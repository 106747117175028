@use 'sass:math';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import './variables.scss';
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

#__next {
  height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

hr {
  margin-top: 12px;
  margin-bottom: 12px;
}

// Spacing - {Margin, padding etc}
@for $i from 1 through 24 {

  //#region margin
  .mt-#{$i} {
    margin-top: #{$i * $spacing};
  }
  .mb-#{$i} {
    margin-bottom: #{$i * $spacing};
  }
  .ml-#{$i} {
    margin-left: #{$i * $spacing};
  }
  .mr-#{$i} {
    margin-right: #{$i * $spacing};
  }
  .mh-#{$i} {
    margin-left: #{$i * $spacing};
    margin-right: #{$i * $spacing};
  }
  .mv-#{$i} {
    margin-top: #{$i * $spacing};
    margin-bottom: #{$i * $spacing};
  }
  .m-#{$i} {
    margin: #{$i * $spacing};
  }
  //#endregion

  //#region padding
  .pt-#{$i} {
    padding-top: #{$i * $spacing};
  }
  .pb-#{$i} {
    padding-bottom: #{$i * $spacing};
  }
  .pl-#{$i} {
    padding-left: #{$i * $spacing};
  }
  .pr-#{$i} {
    padding-right: #{$i * $spacing};
  }
  .ph-#{$i} {
    padding-left: #{$i * $spacing};
    padding-right: #{$i * $spacing};
  }
  .pv-#{$i} {
    padding-top: #{$i * $spacing};
    padding-bottom: #{$i * $spacing};
  }
  .p-#{$i} {
    padding: #{$i * $spacing};
  }

  // direction
  .t-#{$i} {
    top: #{$i * $spacing}
  }
  .r-#{$i} {
    right: #{$i * $spacing}
  }
  .l-#{$i} {
    left: #{$i * $spacing}
  }
  .b-#{$i} {
    bottom: #{$i * $spacing}
  }

  //#endregion

  .w-#{$i} {
    width: #{$i * $spacing};
  }
  .wx-#{$i} {
    width: #{math.div($i, 2) * $spacing};
  }
  .h-#{$i} {
    height: #{$i * $spacing};
  }
  
}

.w-100 {
  width: 100%;
}

.m-auto {
  margin: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mh-auto {
  margin: 0 auto;
}
.mv-auto {
  margin: auto 0;
}

// alignments
@each $alignment in $alignments {
  .t-#{$alignment} {
    text-align: $alignment
  }
}

.c-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.d-block {
  display: block;
}
.justify-center {
  justify-content: center;
}
.justify-right {
  justify-content: flex-end;
}
.justify-space-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.align-right {
  align-items: flex-end;
}
.font-xs {
  font-size: 11px;
}
.font-sm {
  font-size: 13px;
}
.font-md {
  font-size: 15px;
}
.font-lg {
  font-size: 18px;
}
.font-xl {
  font-size: 24px
}
.of-hidden {
  overflow: hidden;
}

@each $c in map-keys($colors) {
  .text-#{$c} {
    color: map-get($colors, $c);
  }
}

.full-screen {
  width: 100%;
  height: 100%;
}

.rs-picker-menu{
  z-index: 9999;
}

.avatar {
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: 'inline-flex';
  align-items: 'center';
  justify-content: 'center';
}